import { Tag } from 'antd';
  
  export const TypeFeatures = (hotl) => {
    return ( <><> </>
      { (hotl.isEasy) ? (<Tag color="green">Easy</Tag>) : (<>
        { (hotl.isModerate) ? (<Tag color="yellow">Moderate</Tag>) : (<>
          { (hotl.isStrenuous) ? (<Tag color="red">Strenuous</Tag>) : (<></>) }
        </>) }
      </>) }
      { (hotl.isLandmark) ? (<Tag color="magenta">Landmarks</Tag>) : (<></>) }
      { (hotl.isHistory) ? (<Tag color="cyan">History & Culture</Tag>) : (<></>) }
      { (hotl.isNature) ? (<Tag color="purple">Nature</Tag>) : (<></>) }
      { (hotl.isWaterAct) ? (<Tag color="blue">Water Activities</Tag>) : (<></>) }
      { (hotl.isSustainable) ? (<Tag color="gray">Sustainable</Tag>) : (<></>) }
      { (hotl.isShopping) ? (<Tag color="geekblue">Shopping</Tag>) : (<></>) }
      { (hotl.hasRestrooms) ? (<Tag color="lime">Restrooms Marked</Tag>) : (<></>) }
      { (hotl.hasRefreshments) ? (<Tag color="orange">Refreshments Marked</Tag>) : (<></>) }
      </> )
  };   
  
export const isUserUsingMobile = () => {

  // User agent string method
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  // Screen resolution method
  if (!isMobile) {
    let screenWidth = window.screen.width;
    let screenHeight = window.screen.height;
    isMobile = (screenWidth < 768 || screenHeight < 768);
  }

  // Touch events method
  if (!isMobile) {
    isMobile = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
  }

  // CSS media queries method
  if (!isMobile) {
    let bodyElement = document.getElementsByTagName('body')[0];
    isMobile = window.getComputedStyle(bodyElement).getPropertyValue('content').indexOf('mobile') !== -1;
  }

  return isMobile
}
