import { Modal } from "antd";
import { currencyFormatter } from "../../actions/stripe";



const OrderModal = ({ session, orderedBy, showModal, setShowModal, createdAt }) => {
  return (
    <Modal
      visible={showModal}
      title="Order payment info"
      onCancel={() => setShowModal(!showModal)}
    >
      <p>Transaction id: {session.payment_intent}</p>
      <p>Payment status: {session.payment_status}</p>
      <p>
        Amount total: {currencyFormatter({
                    amount: session.amount_total,
                    currency: "cad",
                  })}
      </p>
      <p>
      <i>Posted on:  {`${createdAt ? new Date(createdAt).toLocaleString() : ""}`}</i><br/>
      </p>
      <p>Stripe customer id: {session.customer}</p>
      <p>Customer: {orderedBy.name}</p>
    </Modal>
  );
};

export default OrderModal;
