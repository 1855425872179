import { useState } from "react";
import { toast } from "react-toastify";
import { settings } from "../actions/auth";
import { Result } from "antd";
import SettingsForm from "../components/SettingsForm";
import { useSelector } from "react-redux";

const Settings = ({ history }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const { user, token } = auth;
  const [name, setName] = useState(user.name);
  const [email] = useState(user.email);
  const [newEmail, setNewEmail] = useState(user.email);
  const [password, setPassword] = useState("not reset");
  const [repassword, setRePassword] = useState("not reset");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (token) {
      try {
        const res = await settings(token, {
          name,
          email,
          newEmail: newEmail,
          password,
          code: `A${Math.random().toString(36).slice(2)}`
        });
        console.log("Settings USER ===> ", res);
        toast.success("Settings change success. Please login.");
        window.localStorage.removeItem("auth");
        history.push("/login");
      } catch (err) {
        console.log(err);
        if (err.response.status === 400) toast.error(err.response.data);
      }
    }
  };

  return (
    <>
      <div style={{backgroundImage:"url(/backgrounds/settings.jpg)", backgroundSize:"100% 110%"}} className="container-fluid bg-secondary p-5 text-center">
        <h1>Account Settings</h1>
      </div>
      <Result
          status="warning"
          title="We are not live!"
          subTitle="All transactions are test accounts. No postings or payments are real."
        />
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <SettingsForm
              handleSubmit={handleSubmit}
              name={name}
              setName={setName}
              email={newEmail}
              setEmail={setNewEmail}
              password={password}
              setPassword={setPassword}
              repassword={repassword}
              setRePassword={setRePassword}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
