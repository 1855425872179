import { Alert } from "antd";

const SettingsForm = ({
  handleSubmit,
  email,
  name,
  setEmail,
  password,
  setPassword,
  repassword,
  setRePassword,
}) => (
  <form onSubmit={handleSubmit} className="mt-3">
    <div className="form-group mb-3">
      <label className="form-label">Your name</label>
      <input
        type="text"
        className="form-control"
        placeholder="Enter name"
        value={name}
        readOnly={true}
      />
    </div>

    <div className="form-group mb-3">
      <label className="form-label">Email address</label>
      <input
        type="email"
        className="form-control"
        placeholder="Enter email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </div>

    <div className="form-group mb-3">
      <label className="form-label">Password</label>
      <input
        type="password"
        className="form-control"
        placeholder="Enter password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>

    <div className="form-group mb-3">
      <label className="form-label">Reenter Password</label>
      <input
        type="password"
        className="form-control"
        placeholder="Reenter password"
        value={repassword}
        onChange={(e) => setRePassword(e.target.value)}
      />
    </div>

    <button disabled={!email || password !== repassword || password.length < 8} className="btn btn-primary">
      Submit
      </button> <br></br>
    {(password !== repassword) ? (
    <Alert  style={{margin:"1em 0 0 0"}}  type="error" message="Passwords do not match" />
    ) : ""}
    {(password.length < 8) ? (
    <Alert  style={{margin:"1em 0 0 0"}}  type="warning" message="Password needs to be 8 characters or more" />
    ) : ""}
    <br></br>
  </form>
);

export default SettingsForm;
