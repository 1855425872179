// import those pages in App.js
// then based on the path show each components using react-router components
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import TopNav from "./components/TopNav";
import BottomNav from "./components/BottomNav";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./scrollToTop";
// components
import Home from "./booking/Home";
import Login from "./auth/Login";
import Register from "./auth/Register";
import Dashboard from "./user/Dashboard";
import DashboardSeller from "./user/DashboardSeller";
import DashboardUpload from "./user/DashboardUpload";
import NewSite from "./hotels/NewSite";
import StripeCallback from "./stripe/StripeCallback";
import EditSite from "./hotels/EditSite";
import ViewSite from "./hotels/ViewSite";
import StripeSuccess from "./stripe/StripeSuccess";
import StripeCancel from "./stripe/StripeCancel";
import SearchResult from "./hotels/SearchResult";


import "react-toastify/dist/ReactToastify.css";
import Settings from "./auth/Settings";

/**
 * Lets create TopNavigation/menu bar so that we can easily TopNavigate between pages
 * lets write it in App.js before we move it to its own component
 */

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <TopNav />
      <ToastContainer position="top-center" />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute
          exact
          path="/dashboard/upload"
          component={DashboardUpload}
        />
        <PrivateRoute
          exact
          path="/dashboard/seller"
          component={DashboardSeller}
        />
        <PrivateRoute exact path="/tours/new" component={NewSite} />
        <PrivateRoute
          exact
          path="/stripe/callback"
          component={StripeCallback}
        />
        <PrivateRoute exact path="/tour/edit/:hotelId" component={EditSite} />
        <Route exact path="/tour/:hotelId" component={ViewSite} />
        <PrivateRoute
          exact
          path="/stripe/success/:hotelId"
          component={StripeSuccess}
        />
        <PrivateRoute exact path="/stripe/cancel" component={StripeCancel} />
        <Route exact path="/search-result" component={SearchResult} />
      </Switch>
      <BottomNav />
    </BrowserRouter>
  );
}

export default App;
