import axios from "axios";

export const register = async (user) =>
  await axios.post(`${process.env.REACT_APP_API}/register`, user);

export const settings = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/settings`, data, {
    headers: { 
      Authorization: `Bearer ${token}`,
    },
  });

  export const login = async (user) =>
  await axios.post(`${process.env.REACT_APP_API}/login`, user);


  export const setQRCode = async (token,  data) =>
    await axios.post(`${process.env.REACT_APP_API}/setqr`, data, {
      headers: { 
        Authorization: `Bearer ${token}`,
      },
    });


  // update user in local storage
export const updateUserInLocalStorage = (user, next) => {
  if (window.localStorage.getItem("auth")) {
    let auth = JSON.parse(localStorage.getItem("auth"));
    auth.user = user;
    localStorage.setItem("auth", JSON.stringify(auth));
    next();
  }
};
