import { Checkbox, Select } from "antd";
import { tourTypeString } from "../../actions/hotel";

const { Option } = Select;

const TourEditForm = ({
  values,
  setValues,
  handleChange,
  handleImageChange,
  handleImageChange2,
  handleImageChange3,
  handleSubmit,
}) => {
  const { 
    title, 
    layout,  
    content, 
    price, 
    distance,
    duration,
    isLandmark,
    isHistory,
    isNature,
    isWaterAct,
    isSustainable,
    isEasy,
    isModerate,
    isStrenuous,
    isShopping,
    hasRestrooms,
    hasRefreshments,
  } = values;

  const distString = (distance) ? `${(Math.round(distance * 0.62137119 * 100) / 100).toFixed(2)} miles   ${Math.floor(distance * 1000)} meters` : "Verify and Save Path to Calculate Distance";
  const timeString = (duration) ? `${Math.round(duration/60)}h ${Math.round(duration%60)}m` : "Verify and Save Path to Calculate Duration";

  return ( <>
    <form onSubmit={handleSubmit}>
      <div className="form-group">

        <input
          type="text"
          name="title"
          onChange={handleChange}
          placeholder="Title"
          className="form-control m-2"
          value={title}
        />

        <textarea
          name="content"
          onChange={handleChange}
          placeholder="Content"
          className="form-control m-2"
          value={content}
        />

        <div>Price ($USD) - <span style={{color:"red"}}>{(price == -1) ? "Inactive" : ""} </span>
        <span style={{color:"green"}}>{(price == -1) ? "" : (price == 0) ? "Free": ""} </span>
        <span style={{color:"blue"}}>{(price == -1) ? "" : (price == 0) ? "": "Charging for Tour"} </span></div>
        <input
          type="number"
          name="price"
          onChange={handleChange}
          placeholder="Price"
          className="form-control m-2"
          value={price}
        />

        <input
          type="number"
          name="distance"
          onChange={handleChange}
          placeholder={distString}
          className="form-control m-2"
          readOnly={true}
        />

        <input
          type="number"
          name="duration"
          onChange={handleChange}
          placeholder={timeString}
          className="form-control m-2"
          readOnly={true}
        />

      <Select
          onChange={(value) => setValues({ ...values, layout: value })}
          className="w-100 m-2"
          size="large"
          placeholder="Tour Type"
          value={tourTypeString(layout)}
        >
          <Option key={1}>{"Walking, Urban"}</Option>
          <Option key={2}>{"Walking, Trail"}</Option>
          <Option key={3}>{"Walking, Historic"}</Option>
          <Option key={4}>{"Walking, Nature"}</Option>
          <Option key={5}>{"Accessible"}</Option>          
          <Option key={6}>{"Biking, Urban"}</Option>
          <Option key={7}>{"Biking, Trail"}</Option>
          <Option key={8}>{"Biking, Historic"}</Option>
          <Option key={9}>{"Biking, Nature"}</Option>
          <Option key={10}>{"Water"}</Option>
          <Option key={11}>{"Skates, Hoverboard, Skateboard"}</Option>
          <Option key={12}>{"Electric Vehicle"}</Option>
          <Option key={13}>{"Power Vehicle"}</Option>
        </Select>
      </div>

      <div style={{display:"flex"}}>
        <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;
          <Checkbox
            onChange={(value) => setValues({ ...values, isEasy: value.target.checked })}
            checked = {isEasy}
          > Easy</Checkbox>
        </div>
        <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;
          <Checkbox
            onChange={(value) => setValues({ ...values, isModerate: value.target.checked })}
            checked = {isModerate}
          > Moderate</Checkbox>
        </div>
        <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;
          <Checkbox
            onChange={(value) => setValues({ ...values, isStrenuous: value.target.checked })}
            checked = {isStrenuous}
          > Strenuous</Checkbox>
        </div>
      </div>
      <br></br>
      <div style={{display:"flex"}}>
        <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;
          <Checkbox 
            onChange={(value) => setValues({ ...values, isLandmark: value.target.checked })}
            checked = {isLandmark}
          > Landmarks</Checkbox>
        </div>
        <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;
          <Checkbox
            onChange={(value) => setValues({ ...values, isHistory: value.target.checked })}
            checked = {isHistory}
          > History & Culture</Checkbox>
        </div>
        <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;
          <Checkbox 
            onChange={(value) => setValues({ ...values, isNature: value.target.checked })}
            checked = {isNature}
          > Nature</Checkbox>
        </div>
      </div>
      <div style={{display:"flex"}}>
        <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;
          <Checkbox 
            onChange={(value) => setValues({ ...values, isWaterAct: value.target.checked })}
            checked = {isWaterAct}
          > Water Activities</Checkbox>
        </div>
        <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;
          <Checkbox 
            onChange={(value) => setValues({ ...values, isSustainable: value.target.checked })}
            checked = {isSustainable}
          > Sustainable</Checkbox>
        </div>
        <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;</div>
      </div>
      <br></br>
      <div style={{display:"flex"}}>
        <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;
          <Checkbox 
            onChange={(value) => setValues({ ...values, isShopping: value.target.checked })}
            checked = {isShopping}
          > Shopping</Checkbox>
        </div>
        <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;
          <Checkbox
            onChange={(value) => setValues({ ...values, hasRestrooms: value.target.checked })}
            checked = {hasRestrooms}
          > Restrooms Marked</Checkbox>
        </div>
        <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;
          <Checkbox
            onChange={(value) => setValues({ ...values, hasRefreshments: value.target.checked })}
            checked = {hasRefreshments}
          > Refreshments Marked</Checkbox>
        </div>
      </div>
      <p>&nbsp;
    </p>
      <button className="btn btn-outline-primary m-2">Save Details</button>

    </form>
</>  
  );
};

/*
      {from && (
        <DatePicker
          defaultValue={moment(from, "YYYY-MM-DD")}
          placeholder="From date"
          className="form-control m-2"
          onChange={(date, dateString) =>
            setValues({ ...values, from: dateString })
          }
          disabledDate={(current) =>
            current && current.valueOf() < moment().subtract(1, "days")
          }
        />
      )}

      {to && (
        <DatePicker
          defaultValue={moment(to, "YYYY-MM-DD")}
          placeholder="To date"
          className="form-control m-2"
          onChange={(date, dateString) =>
            setValues({ ...values, to: dateString })
          }
          disabledDate={(current) =>
            current && current.valueOf() < moment().subtract(1, "days")
          }
        />
      )}



*/

export default TourEditForm;
