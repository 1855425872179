import React, { useState, useEffect, useRef } from "react";
import EXIF from 'exif-js'
import { toast } from "react-toastify";

// https://github.com/bezkoder/multiple-image-upload-react-js //


const GPSImagesProcess = (
  { createPath = (f) => f, }
) => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [thePath, setThePath] = useState([]);
  const [imageInfos, setImageInfos] = useState([]);

  useEffect(() => {
//    UploadService.getFiles().then((response) => {
//      setImageInfos(response.data);
//    });
  }, []);

  function ConvertDMSToDD(degrees, minutes, seconds, direction) {
    var dd = degrees + (minutes/60) + (seconds/3600);
    if (direction == "S" || direction == "W") {
        dd = dd * -1; 
    }
    return dd;
  }

  function ConvertArrayToDD(coord, direction) {
    if (coord && coord.length === 3) {
      var dd = (coord[0] * 1.0) + (coord[1]/60) + (coord[2]/3600);
      if (direction == "S" || direction == "W") {
          dd = dd * -1; 
      }
      return dd;
    }
  }

  function parseCustomDate(dateString) {
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split(':').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);
    return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
  }

  let imagesUploaded = [];

  const DirectionStrings = ["N", "NE", "E", "SE", "S", "SW", "W", "NW", "N"];

  function imageTimer() {
    if (imagesUploaded && imagesUploaded.length) {
      imagesUploaded.shift();
      setImagePreviews(imagesUploaded);
      setSelectedFiles([]);
      setTimeout(imageTimer, 200);
    }
  }

  const selectFiles = (event) => {
    let imgInfos = [];
    var currentPath = thePath;

    for (let i = 0; i < event.target.files.length; i++) {
      imagesUploaded.push(URL.createObjectURL(event.target.files[i]));
      let file = event.target.files[i];

      EXIF.getData(file, function() {
        try {
          var allMetaData = EXIF.getAllTags(this);
          imgInfos.push(allMetaData);
          var lat =  ConvertArrayToDD(allMetaData.GPSLatitude, allMetaData.GPSLatitudeRef);
          var lon =  ConvertArrayToDD(allMetaData.GPSLongitude, allMetaData.GPSLongitudeRef);
          var alt = allMetaData.GPSAltitude * 1.0;
          var dir = allMetaData.GPSImgDirection * 1.0;
          
          var datetime = parseCustomDate(allMetaData.DateTime);
          currentPath.push({ lat: lat, lon: lon, alt: alt, dir: dir, time: datetime.toISOString(), dt: datetime });
        }
        catch (e) { }
        currentPath = currentPath.sort((a, b) => a.dt - b.dt);
        setThePath(currentPath);
      });
    }
    event.target.value = null;

    let timer = setTimeout(imageTimer, 2500);

    setSelectedFiles(event.target.files);
    setImagePreviews(imagesUploaded);
  };

  const uploadImages = () => {
    if (createPath)
      createPath(thePath);
    setThePath([]);
    setSelectedFiles(undefined);
    setImagePreviews([]);
    setImageInfos([]);
    toast.success("Your New Tour is Being Processed");
    setTimeout(() => {
      window.location = "/dashboard/seller";
    }, 5000);
  };

  return (
    <div>
      <div className="row">
        <div className="col-8">
          Drop Files below<br />
          <label className="btn btn-default p-0 drop-target">
            <input
              class="custom-file-input  drop-target" 
              type="file"
              multiple
              accept="image/*"
              onChange={selectFiles}
            />
          </label> 
        </div>

        <div className="col-4">
          <button
            className="btn btn-success btn-sm"
            disabled={!selectedFiles}
            onClick={uploadImages}
          >
            All files added. Upload result.
          </button>
        </div>
      </div>


      {imagePreviews && (
        <div>
          {imagePreviews.map((img, i) => {
            return (
              <img className="gps-img-preview" src={img} alt={"image-" + i} key={i} />
            );
          })}
        </div>
      )}

      {thePath.length > 0 && (
        <div className="alert alert-secondary mt-2" role="alert">Current Path:
          <ul>
            {thePath.map((item, i) => {
              return <li key={i}>{item.lat.toFixed(7)}, {item.lon.toFixed(7)} {DirectionStrings[Math.floor((item.dir + (360/8)) / 45)] } at {item.dt.toLocaleString('en-US', {hour12: true})}</li>;
            })}
          </ul>
        </div>
      )}

      {imageInfos.length > 0 && (
        <div className="card mt-3">
          <div className="card-header">List of Images</div>
          <ul className="list-group list-group-flush">
            {imageInfos &&
              imageInfos.map((img, index) => (
                <li className="list-group-item" key={index}>
                  <p>
                    <a href={img.url}>{img.name}</a>
                  </p>
                  <img src={img.url} alt={img.name} height="80px" />
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default GPSImagesProcess;