import axios from "axios";

export const createAttachment = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-attachment`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const allAttachments = async (siteId) =>
  await axios.get(`${process.env.REACT_APP_API}/attachments/${siteId}`);

export const deleteAttachment = async (token, attachmentId) =>
  await axios.delete(`${process.env.REACT_APP_API}/delete-attachment/${attachmentId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const read = async (attachmentId) =>
  await axios.get(`${process.env.REACT_APP_API}/attachment/${attachmentId}`);

export const updateAttachment = async (token, data, attachmentId) =>
  await axios.put(
    `${process.env.REACT_APP_API}/update-attachment/${attachmentId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

