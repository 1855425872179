import { useState, useEffect } from "react";
import queryString from "query-string";
import { useSelector } from "react-redux";
import Search from "../components/forms/Search";
import { searchListings, userTourBookedList } from "../actions/hotel";
import SmallCard from "../components/cards/SmallCard";
import { Empty, Typography } from "antd";

const SearchResult = () => {
  // state
  const [hotels, setHotels] = useState([]);
  const [mySites, setMySites] = useState([]);
  const { auth } = useSelector((state) => ({ ...state }));
  // when component mounts, get search params from url and use to send search query to backend
  useEffect(() => {
  
    const { location, duration, storageType, lat, lng } = queryString.parse(window.location.search);
//    console.table({ location, duration, storageType });
    searchListings({ location, duration, storageType, lat, lng }).then((res) => {
      console.log("SEARCH RESULTS ===>", res.data);
      console.log("AUTH ===>", auth);
      if (auth && auth.token) {
        userTourBookedList(auth.token).then((res2) => {
          console.log("SEARCH Booked RESULTS ===>", res2.data);
          setMySites(res2.data);
        });
      }
      setHotels(res.data);
    });
    // eslint-disable-next-line
  }, [window.location.search]);

  const hasPurchased = (hotelId) => {
    return (mySites.findIndex(item => item.hotel === hotelId) === -1) ? false : true;
  }


  return (
    <>
      <div className="col">
        <br />
        <Search />
      </div>
      <div className="container">
      {hotels.length > 0 ? (
        <div className="row">
        {hotels.map((h) => (
            <SmallCard bought={hasPurchased(h._id)} key={h._id} h={h} />
          ))}
        </div>) : (
        <div className="row">
              <Empty description={
                <Typography.Text>
                  Try changing the zoom on the location map
                </Typography.Text>
              } />
        </div>
        )}
      </div>
    </>
  );
};

export default SearchResult;
