import React, { Component } from 'react';

export class MapLine extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hover: false
        }
    }

    componentDidMount() {
    }

    render() {
        const { mapState, latLngToPixel, coordsArray, style } = this.props

        if (coordsArray.length < 2) {
            return null
        }

        let lines = []
        let pixel = latLngToPixel(coordsArray[0])
        let lastpushed = [0, 0]
        let bigList = coordsArray.length > 100

        for (let i = 1; i < coordsArray.length; i++) {
            let pixel2 = latLngToPixel(coordsArray[i])
            let aa = Math.floor(pixel[0])
            let ab = Math.floor(pixel[1])
            let ac = Math.floor(pixel2[0])
            let ad = Math.floor(pixel2[1])

            if (!bigList || (Math.abs(aa) < 900 && Math.abs(ab) < 500 && Math.abs(ac) < 900 && Math.abs(ad) < 500)) {
                if (ac !== lastpushed[0] || ad !== lastpushed[1]) {
                    lines.push(<line key={i} x1={aa} y1={ab} x2={ac} y2={ad} style={style} />)
                    lastpushed = [ac, ad]
                }
            }
            pixel = pixel2
        }

        return (
            <svg width={mapState.width} height={mapState.height} style={{ position: 'absolute', top: 0, left: 0 }}>
                {lines}
            </svg>
        )
    }

}