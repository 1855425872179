import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DeleteOutlined, CheckCircleFilled } from "@ant-design/icons";
import { allAttachments, deleteAttachment } from "../../actions/attachment";


// add new data here

const SitePublicAttachmentForm = ({
  h,
  allowEdit,
  imageMode,
  selectedImg,
  onSelect,
  selectedObj
}) => {
//  const { P, 
 // } = selectedObj;
  const { auth } = useSelector((state) => ({ ...state }));

  const [attachs, setAttachments] = useState([]);
  const [refreshNum, setRefreshNum] = useState([]);
  

  useEffect(() => {
    loadAllAttachments();
    // eslint-disable-next-line
  }, []);

  const loadAllAttachments = async () => {
    let res = await allAttachments(
      h
    );
    console.log(res.data);

    setAttachments(res.data);
  };

  const handleAttachmentDelete = async (attId) => {
    if (!window.confirm("Are you sure?")) return;
    deleteAttachment(auth.token, attId).then((res) => {
      toast.success("Attachment Removed");
      loadAllAttachments();
    });
  };

  const didSelect = (e) => {
    selectedImg = e.target.id;
    setRefreshNum(refreshNum + 1);
    onSelect(e);
  }
  
//  console.log(auth);
  return (
    <>    
      {(attachs.length) ? attachs.map((h) => ( 
         (imageMode) ? <>
           <button onClick={didSelect} className={(selectedImg === h._id) ? "btn btn-outline-primary m-2" : "btn m-2"} style={{width:'35%'}} id={h._id} key={h._id} tick={refreshNum} >
             <h4 id={h._id} >{h.description}{(selectedImg === h._id) ? (<CheckCircleFilled style={{position:"absolute", height:"2em", margin:"3px 0 0 6px"}} />) : ""}</h4>
            <img id={h._id} alt="attachment" src={`${process.env.REACT_APP_API}/attachment/${h._id}`} width='15%' />
          </button>
         </>
          : <span>
          <div key={h._id} >
          <h4>{h.description}</h4>
            <div>Uploaded on: {`${h.createdAt ? new Date(h.createdAt).toLocaleString() : ""}`}</div>
            <div className="form-group">
              <a href={`${process.env.REACT_APP_API}/attachment/${h._id}`} rel="noreferrer" target="_blank">Download </a>
              &nbsp;&nbsp;&nbsp;
              {
                (auth && auth.user && auth.user._id === h.uploadedBy._id && allowEdit) ? 
                  (
                    <DeleteOutlined
                      onClick={() => handleAttachmentDelete(h._id)}
                      className="text-danger" />
                  ) : (<></>) 
              }
            </div>
          </div>
          </span>
        
      )) : <></>}
    </>
  );
};


export default SitePublicAttachmentForm;
//            <button onClick={didSelect} className={(P === h._id) ? "btn btn-outline-primary m-2" : "btn m-2"} width='20%' id={h._id} key={h._id} tick={refreshNum} >
