import React, { useState } from "react";
import SitePublicAttachmentForm from "../cards/SitePublicAttachmentForm";
import { MapControl } from "../map/MapControl";
import { CheckCircleFilled } from "@ant-design/icons";


export const formatTimestamp = function (d) {
  let curr_hour = d.getHours();
  curr_hour = curr_hour + "";
  if (curr_hour.length === 1) {
    curr_hour = "0" + curr_hour;
  }

  let curr_min = d.getMinutes();
  curr_min = curr_min + "";
  if (curr_min.length === 1) {
    curr_min = "0" + curr_min;
  }

  let curr_sec = d.getSeconds();
  curr_sec = curr_sec + "";
  if (curr_sec.length === 1) {
    curr_sec = "0" + curr_sec;
  }

  return curr_hour + ":" + curr_min + ":" + curr_sec;
}

export const formatDate = (d) => {
  var curr_date = d.getDate();
  var curr_month = d.getMonth();
  curr_month++;
  var curr_year = d.getFullYear();
  return curr_month + "/" + curr_date + "/" + curr_year;
}

export const timeStampParse = function (stamp) {
  let ret = new Date(stamp);
  if (isNaN(ret)) {
    if (stamp.length > 0) {
      let stmp = stamp.replace(' ', 'T');
      ret = new Date(stmp);
    }
  }
  if (ret.getFullYear() < 1970) {
    ret = new Date(stamp * 1000 * 1000);
  }
  if (ret.getFullYear() === 1970) {
    ret = new Date(stamp * 1000);
  }
  if (isNaN(ret)) {
    ret = new Date("1970-01-01T" + stamp);
  }
  if (isNaN(ret)) {
    ret = new Date("1970-01-01T0" + stamp);
    if (!isNaN(ret)) {
      ret.setTime(ret.getTime() + 1000 * 60 * 60 * 12);
    }
  }
  if (isNaN(ret)) {
    ret = stamp;
  }
  return ret;
}

const TourPathEditForm = ({
  values,
  setValues,
  handleSubmit,
  handleChange
}) => {
  const { _id,
    jroute,
  } = values;
  const startPoint = [jroute.path[0].La, jroute.path[0].Lo];

  const [zoom, setZoom] = useState(14);
  const [minZoom] = useState(13);
  const [maxZoom] = useState(18);
  const [center, setCenter] = useState(startPoint);
  const [selectedNode, setSelectedNode] = useState(-1);
  const [selectedNodeObj, setSelectedNodeObj] = useState(null);
  const [descTxt, setDescTxt] = useState("");

  const handleClick = ({ event, latLng, pixel }) => {
  }

  const handleContextClick = ({ event, latLng, pixel }) => {
    event.preventDefault();
  }

  const handleMarkerClick = (event, payload, anchor) => {
  }

  const handleMarkerContextClick = (event, payload, anchor) => {
    event.preventDefault();
  }

  const onPOITypeChange = (newIndex) => {
    if (selectedNodeObj) {
      selectedNodeObj.O = newIndex;
      setSelectedNodeObj(selectedNodeObj);
      setSelectedNode(selectedNode);
      setValues({ ...values, route: JSON.stringify(jroute) });
    }
  }

  const POITypes = [
    "Path",
    "Photos",
    "Rest Area",
    "Alert!",
    "Restroom",
    "Snacks",
    "Notes",
    "Shopping",
  ];

  const buildMarkers = (troute) => {
    if (troute && troute.path && troute.path.length > 0) {
      let route = troute;
      let marks = [];
      let i = 0;
      for (i = 0; i < route.path.length; i++) {
        let node = route.path[i];
        let pinNum = (i === selectedNode) ? 50 + node.O : node.O;
        const label = (node.O) ? "POI" : "Path";
        let pin = [label, [node.La, node.Lo], 10, `${process.env.PUBLIC_URL}/pins/${pinNum}s.png`, null, POITypes[node.O], i];

        marks.push(pin);
      }
      return marks;
    }
  }

  const buildPathlines = (troute) => {
    if (troute && troute.path && troute.path.length > 0) {
      let route = troute;
      let marks = [];
      let i = 0;
      for (i = 0; i < route.path.length; i++) {
        let node = route.path[i];
        let pline = [node.La, node.Lo]
        marks.push(pline);
      }
      return marks;
    }
  }

  ////////////// Click Drap Map Pins
  ///////////////////////////

  const onDragPinStart = (newAnchor, payload, anchor) => {
    setDescTxt((jroute.path[payload.key[6]].N) ? jroute.path[payload.key[6]].N : "");
    setSelectedNode(payload.key[6]);
  }


  const onDragPinEnd = (newAnchor, payload, anchor) => {
    payload.key[1] = newAnchor;

    let node = jroute.path[payload.key[6]];
    node.La = payload.key[1][0];
    node.Lo = payload.key[1][1];

    setDescTxt((jroute.path[payload.key[6]].N) ? jroute.path[payload.key[6]].N : "");
    setSelectedNode(payload.key[6]);
    setSelectedNodeObj(node);
    setValues({ ...values, route: JSON.stringify(jroute) });
  }

  const onDragPinMove = (newAnchor, payload, anchor) => {
  }

  const calcDeltaTimeStr = () => {
    let dl = calcDeltaTimeSec();
    let s = Math.floor(dl % 60);
    dl /= 60;
    let m = Math.floor(dl % 60);
    let h = Math.floor(dl / 60);
    return h + "h " + m + "m " + s + "s";
  }

  const calcDeltaTimeSec = () => {
    if (selectedNodeObj && selectedNodeObj.TS) {
      const wp1 = timeStampParse(selectedNodeObj.TS).getTime();
      const wp2 = timeStampParse(jroute.path[selectedNode + 1].TS).getTime();;
      const dl = Math.floor((wp2 - wp1) / 1000);   // seconds
      return dl;
    }
    return 0;
  }

  const onDescChange = (data) => {
    if (selectedNodeObj) {
      selectedNodeObj.N = data.currentTarget.value;
      setDescTxt(data.currentTarget.value);
      setValues({ ...values, route: JSON.stringify(jroute) });
    }
  }

  const onChangeTime = (data) => {
    if (selectedNodeObj && selectedNode < jroute.path.length - 1) {
      const curDelta = calcDeltaTimeSec();
      const realDelta = ((1.0 * data.currentTarget.value) - curDelta) * 1000;

      let i = selectedNode + 1;
      for (; i < jroute.path.length; i++) {
        const curN = jroute.path[i];
        //          this seems to have date
        const nTime = timeStampParse(curN.TS).getTime() + realDelta;
        //          but we're putting out a time only, should upload parse off the date and just keep time?
        curN.TS = formatTimestamp(new Date(nTime));
      }
      setSelectedNodeObj(selectedNodeObj);
      setSelectedNode(selectedNode);
      setValues({ ...values, route: JSON.stringify(jroute) });
      //        setTimeTxt(data.currentTarget.value);
    }
  }

  const insertNode = (curNodeIdx) => {
    if (curNodeIdx > 0) {
      let node1 = jroute.path[curNodeIdx - 1];
      let node2 = jroute.path[curNodeIdx];
      const t1 = timeStampParse(node1.TS).getTime();
      const t2 = timeStampParse(node2.TS).getTime();
      const tn = ((t2 + t1) / 2);
      let nodeN = {
        O: 0,
        La: ((node2.La + node1.La) / 2),
        Lo: ((node2.Lo + node1.Lo) / 2),
        A: ((node2.A + node1.A) / 2),
        D: node2.D,
        TS: formatTimestamp(new Date(tn))
      };
      jroute.path.splice(curNodeIdx, 0, nodeN);
      setSelectedNodeObj(jroute.path[curNodeIdx]);
      setSelectedNode(curNodeIdx);
      setValues({ ...values, route: JSON.stringify(jroute) });
    }
  }

  const deleteNode = (curNodeIdx) => {
    jroute.path.splice(curNodeIdx, 1);
    setSelectedNodeObj(jroute.path[curNodeIdx]);
    setSelectedNode(curNodeIdx);
    setValues({ ...values, route: JSON.stringify(jroute) });
  }

  const onAddPreNode = () => {
    if (selectedNode > 0) {
      insertNode(selectedNode);
    }
  }

  const onAddPostNode = () => {
    if (selectedNode < jroute.path.length - 1) {
      insertNode(selectedNode + 1);
    }
  }
  const onDeleteNode = () => {
    if (selectedNode > 0 && selectedNode < jroute.path.length - 1) {
      deleteNode(selectedNode);
    }
  }

  const CalcDist2Points = (lat1, lon1, lat2, lon2, isMetric) => {    // return miles or meters.
    let dist = 0;
    if ((lat1 !== lat2) || (lon1 !== lon2)) {
      const radlat1 = Math.PI * lat1 / 180;
      const radlat2 = Math.PI * lat2 / 180;
      const theta = lon1 - lon2;
      const radtheta = Math.PI * theta / 180;
      dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (isMetric) {
        dist = dist * 1.609344 * 1000;  // meters
        //        } else {             
        //            dist = dist * 5280; // feet
      }
    }
    return dist;
  }

  let lastPointGPX = {};
  let overrideCompass = true;

  const getCompass = (trkpt) => {
    let heading = 0;
    if (!overrideCompass && trkpt.D != undefined && trkpt.D != null && !isNaN(trkpt.D)) {
      heading = trkpt.dir * 1.0;
    } else {
      if (lastPointGPX && lastPointGPX.lat) {
        heading = getCompass(lastPointGPX.La, lastPointGPX.Lo, trkpt.La, trkpt.Lo)
      }
    }
    lastPointGPX = trkpt;
    return heading;
  }



  const saveChanges = (e) => {
    let currentDate = new Date();
    jroute.lastUpdate = `${formatTimestamp(currentDate)} ${formatDate(currentDate)}`;

    jroute.t = jroute.b = jroute.path[0].La;
    jroute.l = jroute.r = jroute.path[0].Lo;

    let lastpt = jroute.path[0];
    jroute.dist = 0;
    let i = 0;
    for (i = 1; i < jroute.path.length; i++) {
      const pt = jroute.path[i];
      // top/bottom
      if (pt.La > jroute.t) {
        jroute.t = pt.La
      }
      if (pt.La < jroute.b) {
        jroute.b = pt.La
      }
      if (pt.Lo > jroute.r) {
        jroute.r = pt.Lo
      }
      if (pt.Lo < jroute.l) {
        jroute.l = pt.Lo
      }
      pt.D= getCompass(pt);

      jroute.dist += CalcDist2Points(pt.La, pt.Lo, lastpt.La, lastpt.Lo);
      lastpt = pt;
    }

    let wp1 = timeStampParse(jroute.path[0].TS);
    let wp2 = timeStampParse(jroute.path[jroute.path.length - 1].TS);
    let dl = Math.floor((wp2 - wp1) / 1000 / 60);
    
    handleChange({ target: { "name": "duration", "value": dl } });
    handleChange({ target: { "name": "distance", "value": jroute.dist } });
    jroute.duration = dl;
    setValues({ ...values, distance: jroute.dist, duration: jroute.duration, route: JSON.stringify(jroute) });
    setValues({ ...values, distance: jroute.dist, duration: jroute.duration });
    handleSubmit(e);
  }

  const nodeImage = (e) => {
    selectedNodeObj.P = e.target.id;
    setSelectedNodeObj(selectedNodeObj);
    setSelectedNode(selectedNode);
    setValues({ ...values, route: JSON.stringify(jroute) });
  }

  const pinButton = (myIndex, selectedIndex) => {
    return (
      <button onClick={() => onPOITypeChange(myIndex)} className={(selectedIndex === myIndex) ? "btn btn-outline-primary m-2" : "btn m-2"} style={{ width: '22%' }} tick={(selectedIndex === myIndex)} >
        <h7>
          <img style={{ position: "absolute", height: "1.8em", margin: "-3px 0 0 -40px" }} alt="attachment" src={`/pins/${myIndex}.png`} width='24px' />
          {POITypes[myIndex]}{(selectedIndex === myIndex) ? (<CheckCircleFilled style={{ position: "absolute", height: "2em", margin: "3px 0 0 6px" }} />) : ""}
        </h7>
      </button>
    );
  }

  const prevNode = () => {
    var thisCurNode = selectedNode - 1;
    if (thisCurNode < 0)
      thisCurNode = 0;
    setSelectedNode(thisCurNode);
    setSelectedNodeObj(jroute.path[thisCurNode]);
    setCenter([jroute.path[thisCurNode].La, jroute.path[thisCurNode].Lo]);
  }

  const nextNode = () => {
    var thisCurNode = selectedNode + 1;
    if (thisCurNode >= jroute.path.length-1)
      thisCurNode = jroute.path.length - 1;
    setSelectedNode(thisCurNode);
    setSelectedNodeObj(jroute.path[thisCurNode]);
    setCenter([jroute.path[thisCurNode].La, jroute.path[thisCurNode].Lo]);
    setZoom(maxZoom);
  }

  if (!selectedNodeObj) {
    prevNode();
  }

  return (
    <>
      <p>
        <button onClick={saveChanges} className="btn btn-outline-primary m-2">Save Path Changes</button>
        <div style={{ textAlign: 'center', marginTop: 50 }}>

          <div style={{ maxWidth: 1000, width: '98%', margin: '0 auto' }}>
            <MapControl
              onClick={handleClick}
              onContextMenu={handleContextClick}
              onVehicleClick={handleMarkerClick}
              onMarkerClick={handleMarkerClick}
              onMarkerContextClick={handleMarkerContextClick}
              onDragPinMove={onDragPinMove}
              onDragPinStart={onDragPinStart}
              onDragPinEnd={onDragPinEnd}
              center={center}
              zoom={zoom}
              dprs={[1, 2]}
              minZoom={minZoom}
              markers={buildMarkers(jroute)}
              pathlines={buildPathlines(jroute)}
              maxZoom={maxZoom}
              defaultWidth={1000}
              defaultHeight={500}
              height={500}
              boxClassname="pigeon-filters"
              vehicles={[]}>
            </MapControl>
          </div>

          <label className="select">
            {(selectedNodeObj != null) ? (
              <>
                <button className="btn btn-primary" disabled={(selectedNode <= 0)} onClick={prevNode} > &lt; </button>&nbsp;
                <button className="btn btn-primary" disabled={(selectedNode <= 0)} onClick={onAddPreNode} >Split before</button>&nbsp;
                <button className="btn btn-primary" disabled={((selectedNode < 0) || (jroute && jroute.path && selectedNode >= jroute.path.length - 1))} onClick={onAddPostNode} >Split after</button>
                <button className="btn btn-primary" disabled={(selectedNode <= 0) || (jroute && jroute.path && selectedNode >= jroute.path.length - 1)} onClick={onDeleteNode} >Remove</button>&nbsp;
                <button className="btn btn-primary" disabled={(jroute && jroute.path && selectedNode >= jroute.path.length - 1)} onClick={nextNode} > &gt; </button>&nbsp;
                <br />
                <div>
                  {pinButton(0, selectedNodeObj.O)}
                  {pinButton(1, selectedNodeObj.O)}
                  {pinButton(2, selectedNodeObj.O)}
                  {pinButton(3, selectedNodeObj.O)}
                  {pinButton(4, selectedNodeObj.O)}
                  {pinButton(5, selectedNodeObj.O)}
                  {pinButton(6, selectedNodeObj.O)}
                  {pinButton(7, selectedNodeObj.O)}
                </div>

                <textarea
                  name="N"
                  onChange={onDescChange}
                  placeholder="Enter Description"
                  className="form-control m-2"
                  rows="5" cols="60"
                  value={descTxt}
                />
                {(selectedNode !== jroute.path.length - 1) ? <span className="form-control m-2">Time to next {calcDeltaTimeStr()}
                  <input value={calcDeltaTimeSec()} onChange={onChangeTime} type="number" min="2" className="form-control m-2"></input>
                </span> : ""}
                <SitePublicAttachmentForm key={_id} imageMode="1" selectedImg={selectedNodeObj.P} selectedObj={selectedNodeObj} onSelect={nodeImage} h={_id} ></SitePublicAttachmentForm>

              </>) : ""
            }
          </label>
        </div>
      </p>
      <button onClick={saveChanges} className="btn btn-outline-primary m-2">Save Path Changes</button>
    </>
  );
};

export default TourPathEditForm;
