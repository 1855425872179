import { useState, useEffect } from "react";
import DashboardNav from "../components/DashboardNav";
import ConnectNav from "../components/ConnectNav";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CreditCardOutlined } from "@ant-design/icons";
import { createConnectAccount } from "../actions/stripe";
import { sellerTours, deleteTour } from "../actions/hotel";
import { Player } from '@lottiefiles/react-lottie-player';
import { toast } from "react-toastify";
import SmallCard from "../components/cards/SmallCard";


const DashboardSeller = () => {
  const { auth } = useSelector((state) => ({ ...state }));
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {   
    loadSellersTours();
    // eslint-disable-next-line
  }, []);

  const loadSellersTours = async () => {
    let { data } = await sellerTours(auth.token);
    setTours(data);
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      let res = await createConnectAccount(auth.token);
      console.log(res); // get login link
      window.location.href = res.data;
    } catch (err) {
      console.log(err);
      toast.error("Stripe connect failed, Try again.");
      setLoading(false);
    }
  };

  const handleTourDelete = async (tourId) => {
    if (!window.confirm("Are you sure?")) return;
    deleteTour(auth.token, tourId).then((res) => {
      toast.success("Site Deleted");
      loadSellersTours();
    });
  };

  const connected = () => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-10">
          <h2>Your Uploaded Tours</h2>
        </div>
        <div className="col-md-2">
          <Link to="/tours/new" className="btn btn-primary">
            + Add New
          </Link>
        </div>
      </div>

      <div className="row">
      {(tours && tours.length) ? tours.map((h) => (
          <SmallCard
            key={h._id}
            h={h}
            showViewMoreButton={false}
            owner={true}
            handleHotelDelete={handleTourDelete}
          />
        )) : (<>
          <Player
            autoplay
            loop
            src="/anims/114398-no-transaction-history.json"
            style={{ height: '300px', width: '300px' }}
          >
          </Player>
          <div className="centered-text"><p className="mt-6"><h5>No tours have been created yet.</h5> </p></div><p>&nbsp;</p>
        </>
        )}
      </div>
    </div>
  );

  const notConnected = () => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 offset-md-3 text-center">
          <div className="p-5 pointer">
            <CreditCardOutlined  className="h1" />
            <h4>Setup payouts to post tours</h4>
            <p className="lead">
              walkingtour.com partners with stripe to transfer earnings to your bank
              account
            </p>
            <button
              disabled={loading}
              onClick={handleClick}
              className="btn btn-primary mb-3"
            >
              {loading ? "Processing..." : "Setup Payouts"}
            </button>
            <p className="text-muted">
              <small>
                You'll be redirected to Stripe to complete the onboarding
                process.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
console.log("Auth", auth.user);
  return (
    <>
      <div style={{backgroundImage:"url(/backgrounds/dash2.jpg)", backgroundSize:"100% 110%"}} className="container-fluid bg-secondary p-5">
        <ConnectNav />
      </div>

      <div className="container-fluid p-4">
        <DashboardNav />
      </div>

      {auth &&
      auth.user &&
      auth.user.stripe_seller &&
      auth.user.stripe_seller.charges_enabled
        ? connected()
        : notConnected()}

      {/* <pre>{JSON.stringify(auth, null, 4)}</pre> */}
    </>
  );
};

export default DashboardSeller;
