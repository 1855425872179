import { useEffect, useState } from "react";
import { Modal } from "antd";
import { allReviews } from "../../actions/review";
import { List, Rate, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { PictureOutlined } from "@ant-design/icons";

// id == 0 to not make not public

const ReviewListModal = ({ id, showModal, setShowModal }) => {
  // destructuring variables from state

  const [reviews, setReviews] = useState([]);
  const [revealedId, setRevealedId] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  
  const desc = ['No rating', 'Inaccurate', 'Poorly planned', "It's OK", 'This is good', 'Absolutely loved it'];

  useEffect(() => {
    loadAllReviews();
    // eslint-disable-next-line
  }, []);

  const loadAllReviews = async () => {
    let res = await allReviews(
      id, (reviews && reviews.length) ? reviews.length : 0
    );
    console.log(res.data);

    setReviews(res.data); 
    setLoading(false);
  };

  const handleInfiniteOnLoad = async () => {
    setLoading(true);

    let res = await allReviews(
      id, (reviews && reviews.length) ? reviews.length : 0
    );

    const newData = reviews.concat(res.data);
    setReviews(newData);
    if (res.data.length === 0)
      setHasMore(false);
    setLoading(false);
    return;
  };

  const clickRate = async (item) => {
    setRevealedId(" - Review ID: " + item._id);
    console.log("item", item)
  };
  
  return (
      <Modal
        width="80%"
        visible={showModal}
        title={"Tour Reviews " + revealedId}
        onCancel={() => setShowModal(!showModal)}
      >


      <div className="demo-infinite-container"
          style={{ height: "70vh", margin: "0 auto", width: "100%", background: 'white', padding: '16px' }}
      >
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={handleInfiniteOnLoad}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          <List
            dataSource={reviews}
            renderItem={item => (
              <List.Item key={item._id}>
                <div style={{ height: "auto", margin: "0 auto", width: "40%", background: 'white', padding: '16px' }} > 
                  <p onClick={()=> {clickRate(item)} } >
                    <Rate disabled value={item.stars} character={<PictureOutlined />} style={{ fontSize: 16 }} allowHalf />
                    <span >&nbsp;{desc[Math.ceil(item.stars)]}</span></p><p>
                    {item.uploadedBy.name}&nbsp; - &nbsp;<i>{`${item.createdAt ? new Date(item.createdAt).toLocaleString() : ""}`}</i><br/>
                  </p> 
                </div>
                <textarea
                  readOnly={true}
                  placeholder="No Review"
                  className="form-control m-2"
                  value={item.description}
                />
              </List.Item>
            )}
          >
            {loading && hasMore && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </div>




      </Modal>
  );
};

export default ReviewListModal;
