import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";


const BottomNav = () => {

  return (
    <div className="nav d-flex justify-content-between footer-div">
      <Link className="nav-link" to="/">
      <h4 className="text-center blue-nav-text" ><HomeOutlined /></h4>
      </Link>

      <div className="text-center blue-nav-text" ><br></br>walkingtour.com</div>

      <div className="text-center blue-nav-text" ><br></br><a className="nav-link pointer" href="mailto://info@walkingtour.com">
        Contact
      </a></div>
      
    </div>
  );
};

export default BottomNav;
