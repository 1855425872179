import { useState } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import { createAttachment } from "../../actions/attachment";
import { toast } from "react-toastify";

// id == 0 to not make not public

const AttachModal = ({ id, showModal, setShowModal }) => {
  const [values, setValues] = useState({
    description: ""
  });
  // destructuring variables from state
  const { 
    description, 
    filename,
    uploading
  } = values;

  const handleDone = async (e) => {
    if (filename && filename.name) {
      setValues({ ...values, "uploading": true });
      let siteData = new FormData();
      siteData.append("uuid", id);
      siteData.append("description", description);
      siteData.append("image", filename);
      try {
        const res = await createAttachment(token, siteData)
        console.log("Attach result");
        console.log(res);
        console.log(res.data._id);
        setShowModal(!showModal);
        toast.success("New attachment added");

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (err) {
        console.log(err); 
        console.log(err.response.data); 
        toast.error(err.response.data);
      }
    }
};
  
  const handleAddAttachment = async (e) => {
    console.log(e.target.files[0]);
    if (e.target.files[0].type === "application/x-msdownload") {
      toast.error("File selection failed.");
    } else {
      setValues({ ...values, "filename": e.target.files[0] });
      let siteData = new FormData();
      siteData.append("uuid", id);
      siteData.append("description", description);
      siteData.append("image", e.target.files[0]);
    }
  };
  // redux
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  
  return (
    <Modal
      visible={showModal}
      title="Attach image for tour"
      onCancel={() => setShowModal(!showModal)}
    >
      <input
          type="text"
          name="description"
          onChange={handleChange}
          placeholder="Description"
          className="form-control m-2"
          value={description}
        />
      <div>{(filename && filename.name) ? filename.name : ""}</div>
      {uploading ? (
        <>Uploading.</>
        ) : (
        <>
          <label className="btn btn-outline-secondary btn-block m-2">
            Select File
            <input
              type="file"
              name="image"
              accept="image/jpeg, image/jpg"
              onChange={handleAddAttachment}
              hidden
            />
          </label>
          <label className={(filename && filename.name && description.length > 0) ?  'btn btn-outline-primary btn-block m-2' : 'btn btn-outline-disabled btn-block m-2'}
          onClick={handleDone}
          >
            Upload
          </label>
        </>
      ) }

    </Modal>
  );
};

export default AttachModal;
