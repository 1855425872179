import DashboardNav from "../components/DashboardNav";
import ConnectNav from "../components/ConnectNav";
import { Link } from "react-router-dom";
import { userTourBookings } from "../actions/hotel";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import BookingCard from "../components/cards/BookingCard";
import MobileQRModal from "../components/modals/MobileQRModal";
import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Tooltip } from "antd";
import { InfoOutlined } from "@ant-design/icons";


const Dashboard = () => {
  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));
  const [booking, setBooking] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    loadUserBookings();
    // eslint-disable-next-line
  }, []);

  const loadUserBookings = async () => {
    const res = await userTourBookings(token);
    console.log(res);
    setBooking(res.data);
  };

  console.log("booking++" + JSON.stringify(booking));

  return (
    <>
      <div style={{backgroundImage:"url(/backgrounds/dash1.jpg)", backgroundSize:"100% 110%"}} className="container-fluid bg-secondary p-5">
        <ConnectNav />
      </div>

      <div className="container-fluid p-4">
        <DashboardNav />
      </div>

      <div className="container-fluid">
      {showModal && (
          <MobileQRModal
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
        <div className="row">
          <div className="col-md-6">
            

            <h2>Your Purchased Tour Library&nbsp;
              <Tooltip title="For each tour below, select the tours you want your phone to download for this trip. Tour data can be large, you may wish to download using Wi-Fi.">
                <Button type="primary" size="small" shape="circle" icon={<InfoOutlined style={{ position: "absolute", margin: "-0.7em 0 0 -0.5em" }} />} />
              </Tooltip>
            </h2><div></div>
          </div>
          <div className="col-md-4" onClick={() => setShowModal(!showModal)}>
            Click for phone QR Code
            <Player
              autoplay
              loop
              src="/anims/132814-nnavigation.json"
              style={{ margin: "0 0 0 1.5em", height: '150px', width: '100px' }}
            >
            </Player>
          </div>
          <div className="col-md-2">
            <Link to="/" className="btn btn-primary">
              Find New Tours
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        {(booking && booking.length) ? booking.map((b) => (
          <BookingCard
            queued={b.queuedForUpload}
            key={b._id}
            hotel={b.hotel}
            session={b.session}
            orderedBy={b.orderedBy}
            createdAt={b.createdAt}
            updatedAt={b.updatedAt}
          />
        )) : (<>
          <Player
            autoplay
            loop
            src="/anims/114398-no-transaction-history.json"
            style={{ height: '300px', width: '300px' }}
          >
          </Player>
          <div className="centered-text"><p className="mt-6"><h5>No tours have been purchased yet.</h5> </p></div><p>&nbsp;</p>
        </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
