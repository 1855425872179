import axios from "axios";

export const createReview = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-review`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const allReviews = async (siteId, cursor) =>
  await axios.put(`${process.env.REACT_APP_API}/reviews/${siteId}/${cursor}`);


export const deleteReview = async (token, reviewId) =>
  await axios.delete(`${process.env.REACT_APP_API}/delete-review/${reviewId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const readReview = async (reviewId) =>
  await axios.put(`${process.env.REACT_APP_API}/review/${reviewId}`, []);

export const readMyReview = async (token, siteId) =>
await axios.put(`${process.env.REACT_APP_API}/review/${siteId}`, [], {
  headers: {
    Testing: "yes",
    Authorization: `Bearer ${token}`,
  },
});

export const updateReview = async (token, data, reviewId) =>
  await axios.put(
    `${process.env.REACT_APP_API}/update-review/${reviewId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

