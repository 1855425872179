import { useState } from "react";
import { currencyFormatter } from "../../actions/stripe";
import { tourTypeString, durationStringMinutes, setQueueDownload } from "../../actions/hotel";
import { TypeFeatures } from "../Helper";
import OrderModal from "../modals/OrderModal";
import { Image, Button, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { DownloadOutlined, InfoOutlined } from "@ant-design/icons";
import { Slideshow, SlideshowItem } from "../slideshow";

const BookingCard = ({ hotel, session, orderedBy, updatedAt, createdAt, queued }) => {
  const [showModal, setShowModal] = useState(false);
  const [curQueued, setCurQueued] = useState(queued);
  console.log(hotel);
  const { auth } = useSelector((state) => ({ ...state }));
  let hotel2 = hotel;
  if (hotel) {
    
    function Photo(src) {
      console.log(src);
      return <img width="275" style={{ maxHeight: "305px", maxWidth: "300px" }} src={src.src}></img>;
    }

  return (
    <>
      <div className="card mb-3">
        <div className="row no-gutters">
          <div className="col-md-4">
            <p>
              {hotel2.image && hotel2.image.contentType ? ( 
                <Slideshow width={100} height={100}>
                  <SlideshowItem>
                      <Photo src={`${process.env.REACT_APP_API}/tour/image/${hotel2._id}`} />
                </SlideshowItem>
                  {hotel2.image2 && hotel2.image2.contentType ? (
                    <SlideshowItem>
                      <Photo src={`${process.env.REACT_APP_API}/tour/image2/${hotel2._id}`} />
                    </SlideshowItem>
                  ) : (<></>)}
                  {hotel2.image3 && hotel2.image3.contentType ? (
                  <SlideshowItem>
                    <Photo src={`${process.env.REACT_APP_API}/tour/image3/${hotel2._id}`} />
                  </SlideshowItem>
                  ) : (<></>)}
                </Slideshow>
              ) : (
                  <img
                    src="/Placeholder400x400.png"
                    alt="default site"
                    width="275" style={{ maxHeight: "305px", maxWidth: "300px" }}
                    className="card-image img img-fluid"
                />
              )}
          </p>
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h3 className="card-title">
                {hotel2.title}{" "}
                <span className="float-right text-primary">
                  {currencyFormatter({
                    amount: hotel2.price * 100,
                    currency: "cad",
                  })}
                </span>{" "}
              </h3>
              <p className="alert alert-info">{hotel2.location}</p>
              <p className="card-text">{`${hotel2.content.substring(
                1,
                200
              )}...`}</p>
              <p className="card-text">
                  in this <span className="float-right text-primary"> {tourTypeString(hotel2.layout)}{" "}
                </span>
                available for <span className="float-right text-primary">
                  {durationStringMinutes(hotel2.duration)}{" "}
                </span>
              </p>

              Features
              <p>
                {TypeFeatures(hotel2)}
              </p>
              {showModal && (
                <OrderModal
                  session={session}
                  orderedBy={orderedBy}
                  showModal={showModal}
                  createdAt={createdAt}
                  updatedAt={updatedAt}
                  setShowModal={setShowModal}
                />
              )}

              <div className="d-flex justify-center h4">
              <Button type="primary" href={`/tour/${hotel2._id}`}>Tour Details</Button>
              <div>&nbsp; &nbsp; </div>
              <Button type="primary" onClick={() => setShowModal(!showModal)}>Show Payment info</Button>
              <div>&nbsp; &nbsp; </div>
              <div>&nbsp; &nbsp; </div>
              <div>&nbsp; &nbsp; </div>
              <Button type="primary" danger={curQueued} icon={<DownloadOutlined />} onClick={() => {setCurQueued((curQueued) ? false : true); setQueueDownload(auth.token, hotel2._id)} }>
                {!curQueued ? (<>Download to Device</>) : (<>Remove from Download</>)}
              </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
} else {
  return (<></>);  
}
};

export default BookingCard;
