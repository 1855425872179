import { Modal } from "antd";
import { useSelector } from "react-redux";
import QRCode from 'react-qr-code';



// id == 0 to not make not public

const MobileQRModal = ({ showModal, setShowModal }) => {
  // destructuring variables from state

  // redux
  const { auth } = useSelector((state) => ({ ...state }));

  const fullURL = `${process.env.REACT_APP_WEBSERVER}/qr.html?id=${auth.user._id}&code=${auth.user.code}`
  
  return (
      <Modal
        visible={showModal}
        title="Scan with your phone."
        onCancel={() => setShowModal(!showModal)}
      >
          <div>{fullURL}</div>
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 412, width: "100%", background: 'white', padding: '16px' }}>
          <QRCode value={fullURL} 
          size={64}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }} 
          />
        </div>
        <div>This code expires when you change your <a href="/settings">settings</a></div>
      </Modal>
  );
};

export default MobileQRModal;
